import { MoonIcon } from '@heroicons/react/24/solid';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Link } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import SignInButton from './SignInButton';

export const Header = ({ subscriber }: { subscriber?: boolean }) => {
	const prefersDarkMode =
		typeof window !== 'undefined' &&
		window.matchMedia('(prefers-color-scheme: light)').matches;
	const [portalUrl, setPortalUrl] = React.useState('');
	const [isDarkMode, setIsDarkMode] = React.useState(
		typeof localStorage !== 'undefined' && localStorage.getItem('theme')
			? localStorage.getItem('theme') === 'dark'
			: prefersDarkMode
	);

	React.useEffect(() => {
		if (isDarkMode) {
			document.documentElement.classList.add('dark');
		} else {
			document.documentElement.classList.remove('dark');
		}
	}, [isDarkMode]);
	const handleDarkMode = (isDark: boolean) => {
		setIsDarkMode(isDark);
		typeof localStorage !== 'undefined' &&
			localStorage.setItem('theme', isDark ? 'dark' : 'light');
	};

	React.useEffect(() => {
		const asyncGetPortalUrl = async () => {
			if (portalUrl || !subscriber) {
				if (!subscriber) {
					setPortalUrl('');
				}
				return;
			}
			const functionRef = httpsCallable(
				getFunctions(undefined, 'us-central1'),
				'ext-firestore-stripe-payments-createPortalLink'
			);
			const { data } = await functionRef({
				locale: 'auto',
				returnUrl: window.location.origin,
			});
			const url = (data as { url?: string })?.url;
			url && setPortalUrl(url);
		};
		asyncGetPortalUrl();
	}, [portalUrl, subscriber]);

	return (
		<>
			<Helmet
				meta={[
					{
						content: isDarkMode ? '#202124' : '#FFFFFF',
						name: `theme-color`,
					},
				]}></Helmet>
			<ul className="Header HeaderFooter" style={{ padding: 'var(--half) 0' }}>
				<div key="headerMenuLeft" className="menuLeft">
					<li key="home">
						<Link to="/">Home</Link>
					</li>

					<li key="feudle">
						<Link to="/feudle/">Feudle</Link>
					</li>
					<li key="store">
						<Link to="/store/">Merch</Link>
					</li>
					{/*
					<li key="adfree">
						<Link to={showAds ? '/adfree' : portalUrl}>
							{!portalUrl ? 'No Ads' : 'Billing'}
						</Link>
					</li>
					*/}
				</div>
				<div key="headerMenuRight" className="menuRight">
					<li key="dark" className="h-full">
						<MoonIcon
							className="h-5 w-5"
							onClick={() => handleDarkMode(!isDarkMode)}
							style={{ cursor: 'pointer' }}
						/>
					</li>
					<li key="profile" id="profile">
						<Link id="signInLink" to="/profile/">
							<SignInButton />
						</Link>
					</li>
				</div>
			</ul>
		</>
	);
};

export default Header;
