import React from 'react';

const LeftPortrait = () => (
	<div
		className="hideOnMobile"
		style={{
			flex: 1,
			left: 0,
			marginLeft: 'var(--half)',
			marginTop: 'var(--double)',
			position: 'absolute',
		}}>
		<div data-fuse="22969192176"></div>
	</div>
);
export default LeftPortrait;
