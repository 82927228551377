import React from 'react';

type MyButtonType = {
	fullWidth?: true;
	bigText?: true;
	signin?: true;
	className?: string;
	buttonBGColor?: string;
	buttonTextColor?: string;
	text?: string;
	order?: number;
} & React.JSX.IntrinsicAttributes &
	React.ClassAttributes<HTMLButtonElement> &
	React.ButtonHTMLAttributes<HTMLButtonElement>;
const AllButtons = ({
	bigText,
	fullWidth,
	className,
	buttonBGColor,
	buttonTextColor,
	order,
	text,
	...otherProps
}: MyButtonType) => (
	<button
		className={className ?? 'MainButton'}
		style={{
			alignItems: 'center',
			backgroundColor: buttonBGColor ?? 'var(--lightgray)',
			border: '1px solid transparent',
			borderRadius: '4px',
			color: buttonTextColor ?? 'var(--black)',
			cursor: 'pointer',
			display: 'flex',
			fontSize: bigText ? '2rem' : '1rem',
			fontWeight: '500',
			height: bigText ? '72px' : '36px',
			justifyContent: 'center',
			lineHeight: '16px',
			margin: 'var(--single)',
			minWidth: bigText ? '360px' : '180px',
			order: order ?? 0,
			outline: 'none',
			userSelect: 'none',
			width: fullWidth ? '100%' : 'auto',
		}}
		{...otherProps}>
		{text}
	</button>
);

export const MainButton = AllButtons;

export const GreenButton = ({ className, ...props }: MyButtonType) => (
	<AllButtons
		{...props}
		buttonBGColor="var(--green)"
		buttonTextColor="var(--white)"
		className={`${className} GreenButton`}
	/>
);

export const SecondaryButton = ({ signin, ...props }: MyButtonType) => (
	<AllButtons
		{...props}
		buttonBGColor="var(--blue)"
		buttonTextColor="var(--white)"
		className={`SecondaryButton ${signin && 'signIn'}`}
	/>
);

export const TertiaryButton = (props: MyButtonType) => (
	<AllButtons
		{...props}
		buttonBGColor="var(--pink)"
		buttonTextColor="var(--white)"
		className={`${props.className} TertiaryButton`}
	/>
);
