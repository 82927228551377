import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';

const MetaData = ({ staticQuery }: { staticQuery?: string }) => {
	const { description, title } = useStaticQuery(graphql`
		query PageQuery {
			site {
				siteMetadata {
					description
					title
				}
			}
		}
	`).site.siteMetadata;
	const site_title = staticQuery
		? `${title} - Answers - ${staticQuery}`
		: title;

	return (
		<>
			<Helmet>
				<meta charSet="UTF-8" />
				<meta
					content="width=device-width, initial-scale=1, shrink-to-fit=no"
					name="viewport"
				/>
				<meta content={description} name="description" />
				<meta content="en-US" httpEquiv="content-language" />
				<meta
					content="google games, google trivia, feud, fued, google game, google search game, autocomplete, auto complete, funny, google suggest, autocorrect, autocompete, app, let's play, @midnight, chris hardwick, pewdiepie, smosh, webbys"
					name="keywords"
				/>

				<meta content="website" property="og:type" />
				<meta content="https://googlefeud.com/" property="og:url" />
				<meta content={site_title} property="og:title" />
				<meta
					content="https://googlefeud.com/images/site_image.jpg"
					property="og:image"
				/>
				<meta content={description} property="og:description" />

				<meta content="https://googlefeud.com/" name="twitter:url" />
				<meta content="photo" name="twitter:card" />
				<meta
					content="https://googlefeud.com/images/site_image.jpg"
					name="twitter:image"
				/>
				<meta content="800" name="twitter:image:width" />
				<meta content="508" name="twitter:image:height" />
				<meta content={site_title} name="twitter:title" />
				<meta content={description} name="twitter:description" />

				<base target="_blank" />
				<script type="application/ld+json">
					{`
							{
								"@context": "http://schema.org/",
								"@type": "VideoGame",
									"award":"Webby Award - Best Game - People's Voice ",
									"dateCreated":"2013-04-23",
									"description": "${description.replace(/"/g, '\\"')}",
									${staticQuery ? `"gameItem":"Answers - ${staticQuery}",` : ''}
									"image":"https://googlefeud.com/images/site_image.jpg",
									"name":"Google Feud",
									"numberOfPlayers":"1",
									"typicalAgeRange":"13+",
									"url":"https://googlefeud.com",
									"inLanguage": "English",
								"author": {
									"@type": "Person",
									"name": "Justin Hook",
									"url": "https://justinhook.com/"
								},
								"genre": ["Trivia", "Quiz"],
								"gamePlatform": "Browser",
								"sameAs": [
									"https://en.wikipedia.org/wiki/Google_Feud",
									"https://poki.com/en/g/google-feud",
									"https://crazygames.com/game/google-feud"
								]
							}
						`}
				</script>
			</Helmet>
			<div style={{ left: '-10000px', position: 'absolute' }}>
				{description}
			</div>
		</>
	);
};
export default MetaData;
