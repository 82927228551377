import * as React from 'react';
import FooterBanner from '../components/Ads/FooterBanner';
import HeaderBanner from '../components/Ads/HeaderBanner';
import LeftPortrait from '../components/Ads/LeftPortrait';
import RightPortrait from '../components/Ads/RightPortrait';
import Footer from '../components/Footer';
import Header from '../components/Header';
import MetaData from '../components/MetaData';

const MainTemplate = ({
	element,
	props,
}: {
	element: React.ReactNode;
	props: {
		staticQuery?: string;
		location: {
			hash: string;
			pathname: string;
		};
	};
}) => {
	const { staticQuery, location } = props;
	const { hash, pathname } = location;

	//	const [subscriptionActive, setSubscriptionActive] = React.useState(false);
	//	const [lifetimeActive, setLifetimeActive] = React.useState(false);
	//	const [user, setUser] = React.useState(auth.currentUser);
	// const auth = getAuth();

	const showAds =
		//		!lifetimeActive &&
		//		!subscriptionActive &&
		hash !== '#noads' && pathname !== '/404/';

	/*

	React.useEffect(() => {
		const unregisterAuthObserver = auth.onAuthStateChanged((currentUser) => {
			setUser(currentUser);
		});
		return () => unregisterAuthObserver();
	}, []);

	React.useEffect(() => {
		if (!user) {
			return;
		}
		onSnapshot(
			collection(getFirestore(), `UserData/${user.uid}/subscriptions`),
			async (snapshot) => {
				setSubscriptionActive(
					snapshot.docs
						.map((doc) => doc.data())
						.filter((data) => data.status === 'active').length > 0
				);
			}
		);
	}, [user]);

	React.useEffect(() => {
		if (!user) {
			return;
		}
		onSnapshot(
			collection(getFirestore(), `UserData/${user.uid}/payments`),
			async (snapshot) => {
				setLifetimeActive(
					snapshot.docs
						.map((doc) => doc.data())
						.filter((data) => data.status === 'succeeded').length > 0
				);
			}
		);
	}, [user]);
	*/

	return (
		<>
			<MetaData staticQuery={staticQuery} />
			<main
				style={{
					display: 'flex',
					flex: '1',
					flexDirection: 'column',
					minHeight: '100vh',
				}}>
				<Header // subscriber={subscriptionActive || lifetimeActive}
				/>
				<div
					style={{
						display: 'flex',
						flex: 1,
						flexDirection: 'row',
						justifyContent: 'space-evenly',
						marginTop: 'var(--single)',
						minHeight: '600px',
					}}>
					{showAds && <LeftPortrait />}
					<div
						className="ElementWrapper"
						style={{
							maxWidth: '90vw',
							minHeight: '600px',
							textAlign: 'center',
							width: '100%',
						}}>
						{showAds && <HeaderBanner />}
						{element}
					</div>
					{showAds && <RightPortrait />}
				</div>
				{showAds && <FooterBanner />}
				<div
					style={{
						color: '#999',
						paddingLeft: '5%',
						textAlign: 'center',
						width: '90%',
					}}>
					<i>Google Feud</i> is a work of parody and not affiliated with Google
					LLC. &quot;Google&quot; and the Google logo are registered trademarks
					of Google LLC.
				</div>
				<Footer />
			</main>
		</>
	);
};
export default MainTemplate;
