exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-adfree-tsx": () => import("./../../../src/pages/adfree.tsx" /* webpackChunkName: "component---src-pages-adfree-tsx" */),
  "component---src-pages-feudle-archive-tsx": () => import("./../../../src/pages/feudle/archive.tsx" /* webpackChunkName: "component---src-pages-feudle-archive-tsx" */),
  "component---src-pages-feudle-index-tsx": () => import("./../../../src/pages/feudle/index.tsx" /* webpackChunkName: "component---src-pages-feudle-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-store-tsx": () => import("./../../../src/pages/store.tsx" /* webpackChunkName: "component---src-pages-store-tsx" */),
  "component---src-pages-tester-tsx": () => import("./../../../src/pages/tester.tsx" /* webpackChunkName: "component---src-pages-tester-tsx" */),
  "component---src-templates-answers-index-tsx": () => import("./../../../src/templates/AnswersIndex.tsx" /* webpackChunkName: "component---src-templates-answers-index-tsx" */),
  "component---src-templates-answers-tsx": () => import("./../../../src/templates/Answers.tsx" /* webpackChunkName: "component---src-templates-answers-tsx" */)
}

