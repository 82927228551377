import { Link } from 'gatsby';
import * as React from 'react';

export const Footer = () => {
	return (
		<ul
			className="Footer HeaderFooter"
			style={{
				backgroundColor: 'var(--lightgray)',
				display: 'flex',
				marginTop: 'var(--double)',
				padding: 'var(--single) 0',
			}}>
			<div key="footerMenuLeft" className="menuLeft">
				<li key="press">
					<Link to="/press/">Press</Link>
				</li>
			</div>
			<div key="footerMenuCenter" className="center hideOnMobile" id="fun">
				<li key="fun" style={{ display: 'none', flex: 1 }}>
					<a
						href="https://askmi.app"
						style={{ flex: 1, textAlign: 'center', whiteSpace: 'nowrap' }}>
						<b>Exclusive Invite</b>: Create Your Askmi Profile
					</a>
				</li>
			</div>
			<div key="footerMenuRight" className="menuRight">
				<li key="about">
					<Link to="/about/">About</Link>
				</li>
			</div>
		</ul>
	);
};

export default Footer;
