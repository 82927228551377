import React from 'react';

const HeaderBanner = () => (
	<div
		id="hb"
		style={{ maxHeight: '199px', maxWidth: '100%', textAlign: 'center' }}>
		<div data-fuse="22969287382"></div>
	</div>
);
export default HeaderBanner;
