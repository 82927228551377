import React from 'react';

const FooterBanner = () => (
	<div
		style={{
			marginBottom: 'var(--single)',
			marginTop: 'var(--single)',
			maxWidth: '100%',
			textAlign: 'center',
		}}>
		<div data-fuse="22969287385"></div>
	</div>
);
export default FooterBanner;
